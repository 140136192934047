// extracted by mini-css-extract-plugin
export var container = "ProductDetailWinter-module--container--db070";
export var desc = "ProductDetailWinter-module--desc--f982f";
export var full = "ProductDetailWinter-module--full--47746";
export var fullBack = "ProductDetailWinter-module--fullBack--620dc";
export var fullBackBottom = "ProductDetailWinter-module--fullBackBottom--e7dd8";
export var fullBackText = "ProductDetailWinter-module--fullBackText--8af2a";
export var image = "ProductDetailWinter-module--image--a94f0";
export var left = "ProductDetailWinter-module--left--4f403";
export var other = "ProductDetailWinter-module--other--bfd02";
export var right = "ProductDetailWinter-module--right--79a50";
export var spec = "ProductDetailWinter-module--spec--63383";
export var specIcon = "ProductDetailWinter-module--specIcon--c0ce2";
export var specIconDouble = "ProductDetailWinter-module--specIconDouble--7b86b";
export var specTitle = "ProductDetailWinter-module--specTitle--4984c";
export var specs = "ProductDetailWinter-module--specs--1f3f7";
export var table = "ProductDetailWinter-module--table--07585";